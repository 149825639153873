.LogoWrapper img {
  width: 700px;
  display: block;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .LogoWrapper img {
    width: 100px;
  }
}

.AdditionalMessageWrapper {
  text-align: center;
  margin: 0 auto;

  font-size: 4em;
  margin-top: 70px;
}

.AdditionalMessageWrapper p {
  margin: 20px;
}

.AdditionalMessageWrapper .middle {
  font-weight: bold;
  font-size: 1.3em;
}

@media only screen and (max-width: 600px) {
  .AdditionalMessageWrapper {
    font-size: 2em;
    margin-top: 10px;
  }
  .AdditionalMessageWrapper .middle {
    font-size: 1em;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .AdditionalMessageWrapper {
    font-size: 2em;
    margin-top: 10px;
  }
  .AdditionalMessageWrapper .middle {
    font-size: 1em;
  }
}

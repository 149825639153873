.DateWrapper {
  color: #fff;
  font-size: 4em;
  margin-left: 15px;
}

@media only screen and (max-width: 600px) {
  .DateWrapper {
    font-size: 2em;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .DateWrapper {
    font-size: 2.5em;
  }
}

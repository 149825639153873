@font-face {
  font-family: 'PT Sans';
  src: url(/static/media/PTSans-Bold.9cc61e4c.woff2) format('woff2'),
    url(/static/media/PTSans-Bold.f94fc443.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PT Sans';
  src: url(/static/media/PTSans-Italic.a3ffa469.woff2) format('woff2'),
    url(/static/media/PTSans-Italic.4a12667d.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'PT Sans';
  src: url(/static/media/PTSans-Regular.8f1aeef4.woff2) format('woff2'),
    url(/static/media/PTSans-Regular.1a7205cc.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PT Sans';
  src: url(/static/media/PTSans-BoldItalic.2965e131.woff2) format('woff2'),
    url(/static/media/PTSans-BoldItalic.e7b1589c.woff) format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'PT Sans Narrow';
  src: url(/static/media/PTSans-Narrow-Web-Bold.5cb36bc8.woff2) format('woff2'),
    url(/static/media/PTSans-Narrow-Web-Bold.4b2d108d.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PT Sans Narrow';
  src: url(/static/media/PTSans-Narrow-Web-Regular.a2bc0efc.woff2) format('woff2'),
    url(/static/media/PTSans-Narrow-Web-Regular.f486073c.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  max-width: 100%;
  padding: 20px;
  padding-top: 0px;
  font-family: 'PT Sans', sans-serif;
  background: linear-gradient(to right, #11846d 50%, #0d6554 50%);
  color: #fff;
}

h1 {
  font-size: 50px;
}



.LogoWrapper img {
  width: 700px;
  display: block;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .LogoWrapper img {
    width: 100px;
  }
}

.ClockWrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: auto;
  padding: 15px;
  background-color: white;
  font-weight: 900;
}

.Clock {
  font-size: 6em;
  min-height: 60px;
  color: #0d6554;
}

@media only screen and (max-width: 600px) {
  .ClockWrapper {
    width: 100%;
    text-align: center;
  }

  .Clock {
    font-size: 4em;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .Clock {
    font-size: 5em;
  }
}

.PrayerTimesWrapper {
  width: 100%;
}

.PrayerTimes {
  font-size: 3.5em;
  text-align: right;
  color: white;
  margin-left: 60px;
  padding-top: 75px;
  width: 850px;
}

.PrayerTimes th {
  font-weight: bolder;
  font-size: 1em;
  text-align: right;
}

.PrayerTimes th:last-child {
  padding-left: 50px;
}

.PrayerTimes td,
.PrayerTimes th {
  margin: 150px;
  padding-bottom: 10px;
}

.PrayerTimes th:first-child {
  font-size: 1.2em;
  text-align: left;
}

.PrayerTimes .mithl-text {
  font-size: 0.5em;
  max-width: 100px;
}

.PrayerTimes td {
  min-width: 100px;
  font-size: 1.3em;
}

.PrayerTimes td:last-child {
  font-weight: bolder;
}

.PrayerTimes td:last-child.normal-text {
  font-weight: normal;
}

@media only screen and (max-width: 600px) {
  .PrayerTimes {
    font-size: 1em;
    margin-left: 0;
    width: 100%;
  }

  .PrayerTimes th:last-child {
    padding-left: 0;
  }

  .PrayerTimes td {
    min-width: 10px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .PrayerTimes {
    font-size: 2em;
    margin-left: 0;
    width: 100%;
    padding: 30px;
  }

  .PrayerTimes th:last-child {
    padding-left: 0;
  }

  .PrayerTimes td {
    min-width: 10px;
  }
}

.DateWrapper {
  color: #fff;
  font-size: 4em;
  margin-left: 15px;
}

@media only screen and (max-width: 600px) {
  .DateWrapper {
    font-size: 2em;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .DateWrapper {
    font-size: 2.5em;
  }
}

.BuildNumberWrapper {
  position: fixed;
  right: 0%;
  bottom: 0%;
  margin: 10px;
  color: grey;
}

.SunriseAndZawwalWrapper {
  margin-top: 70px;
  width: 100%;
  font-size: 3em;
}

.SunriseAndZawwalTable {
  float: right;
  padding-right: 50px;
}

.SunriseAndZawwalTable td {
  min-width: 150px;
}

.SunriseAndZawwalTable th,
.SunriseAndZawwalTable td {
  text-align: right;
  padding-left: 115px;
}

@media only screen and (max-width: 600px) {
  .SunriseAndZawwalWrapper {
    font-size: 1em;
  }

  .SunriseAndZawwalTable th,
  .SunriseAndZawwalTable td {
    padding-left: 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .SunriseAndZawwalWrapper {
    font-size: 2em;
  }

  .SunriseAndZawwalTable th,
  .SunriseAndZawwalTable td {
    padding-left: 100px;
  }
}

.AdditionalMessageWrapper {
  text-align: center;
  margin: 0 auto;

  font-size: 4em;
  margin-top: 70px;
}

.AdditionalMessageWrapper p {
  margin: 20px;
}

.AdditionalMessageWrapper .middle {
  font-weight: bold;
  font-size: 1.3em;
}

@media only screen and (max-width: 600px) {
  .AdditionalMessageWrapper {
    font-size: 2em;
    margin-top: 10px;
  }
  .AdditionalMessageWrapper .middle {
    font-size: 1em;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .AdditionalMessageWrapper {
    font-size: 2em;
    margin-top: 10px;
  }
  .AdditionalMessageWrapper .middle {
    font-size: 1em;
  }
}


.JummahTimesWrapper {
  width: 100%;
  font-size: 3em;
  margin-top: 70px;
}

.JummahTimesTable {
  float: right;
  padding-right: 50px;
  width: 890px;
}

.JummahTimesTable td {
  min-width: 150px;
}

.JummahTimesTable th,
.JummahTimesTable td {
  font-weight: normal;
  text-align: right;
  padding-left: 20px;
}

.JummahTimesTable td:first-child {
  text-align: left;
  padding-left: 0px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .JummahTimesWrapper {
    font-size: 1em;
  }

  .JummahTimesTable {
    width: 100%;
    padding-right: 30px;
    padding-left: 40px;
  }

  .JummahTimesTable th,
  .JummahTimesTable td {
    padding-left: 0;
    min-width: 0;
  }

  .JummahTimesTable td {
    min-width: 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .JummahTimesWrapper {
    font-size: 2em;
  }

  .JummahTimesTable {
    width: 100%;
    padding-right: 30px;
    padding-left: 40px;
  }

  .JummahTimesTable th,
  .JummahTimesTable td {
    padding-left: 0;
    min-width: 0;
  }

  .JummahTimesTable td {
    min-width: 0;
  }
}


.NextJammahTimeWrapper {
  width: 100%;
}

.NextJammahTime {
  font-size: 4em;
  text-align: center;
  color: white;
  margin: 0 auto;
  margin-top: 25vh;
  padding-left: 50px;
}

.NextJammahTime td,
.NextJammahTime th {
  margin: 150px;
}

.NextJammahTime th:first-child {
  font-weight: lighter;
}

.NextJammahTime td {
  min-width: 100px;
  font-size: 1.6em;
}

.NextJammahTime td:last-child {
  font-weight: bolder;
}

@media only screen and (max-width: 600px) {
  .NextJammahTime {
    font-size: 2em;
    margin-top: 10px;
    padding-left: 0;
  }

  .NextJammahTime td {
    min-width: 0;
  }

  .NextJammahTime td,
  .NextJammahTime th {
    margin: 0;
  }
}

.PrayerTimesWeekAheadWrapper {
  margin-top: 100px;
}

.PrayerTimesWeekAheadWrapper {
  width: 100%;
  font-family: 'PT Sans Narrow';
}

.PrayerTimesWeekAhead {
  margin: 0 auto;
  color: #e4e4e4;
}

.PrayerTimesWeekAhead thead tr th {
  font-size: 3em;
  font-weight: bolder;
}

.PrayerTimesWeekAhead td {
  font-size: 2.5em;
  text-align: center;
  padding: 15px 16px 5px;
}

.PrayerTimesWeekAhead th:first-child {
  text-align: right;
}

.PrayerTimesWeekAhead td:first-child {
  text-align: right;
  color: #e4e4e4;
}

.PrayerTimesWeekAhead-row {
  color: white;
  font-weight: bolder;
}

@media only screen and (max-width: 600px) {
  .PrayerTimesWeekAhead thead tr th {
    font-size: 1em;
  }

  .PrayerTimesWeekAhead td {
    font-size: 0.5em;
    text-align: center;
    padding: 0px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .PrayerTimesWeekAhead thead tr th {
    font-size: 2em;
  }

  .PrayerTimesWeekAhead td {
    font-size: 1.5em;
    text-align: center;
    padding: 4px;
  }
}

.HadithOfTheDayWrapper {
  margin-top: 100px;
}

.HadithOfTheDayWrapper {
  min-width: 70%;
  width: auto;
  display: block;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: center;
  background-color: #fff;
  color: #000;
  padding: 50px;
}

.Hadith {
  font-size: 40px;
  text-align: justify;
  margin-top: 40px;
}

.HadithSource {
  font-size: 22px;
  margin: inherit;
}

@media only screen and (max-width: 991px) {
  .HadithOfTheDayWrapper {
    width: 90%;
    margin-top: 10px;
    padding: 10px;
  }

  .HadithOfTheDayTitle h1 {
    font-size: 30px;
  }

  .Hadith {
    font-size: 25px;
    text-align: center;
  }
}


.PrayerTimesSingleViewWrapper {
  width: 100%;
}

.PrayerTimesSingleView {
  font-size: 3em;
  text-align: right;
  color: white;
  margin-left: 60px;
  padding-top: 50px;
  width: 840px;
}

.PrayerTimesSingleView th {
  font-weight: bolder;
  font-size: 1em;
  text-align: right;
}

.PrayerTimesSingleView th:last-child {
  padding-left: 0px;
}

.PrayerTimesSingleView td,
.PrayerTimesSingleView th {
  margin: 150px;
  padding-bottom: 10px;
}

.PrayerTimesSingleView th:first-child {
  font-size: 1.2em;
  text-align: left;
}

.PrayerTimesSingleView .mithl-text {
  font-size: 0.5em;
  max-width: 100px;
}

.PrayerTimesSingleView td {
  min-width: 100px;
  font-size: 1.3em;
}

.PrayerTimesSingleView td:nth-child(3) {
  font-weight: bolder;
}

.PrayerTimesSingleView td:last-child.normal-text {
  font-weight: normal;
}

@media only screen and (max-width: 600px) {
  .PrayerTimesSingleView {
    font-size: 1em;
    margin-left: 0;
    width: 100%;
  }

  .PrayerTimesSingleView th:last-child {
    padding-left: 0;
  }

  .PrayerTimesSingleView td {
    min-width: 10px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .PrayerTimesSingleView {
    font-size: 2em;
    margin-left: 0;
    width: 100%;
    padding: 30px;
  }

  .PrayerTimesSingleView th:last-child {
    padding-left: 0;
  }

  .PrayerTimesSingleView td {
    min-width: 10px;
  }
}

.PrayerTimesSingleView .nextJammahHighlight {
  font-weight: bolder;
  color: #ff3995;
}

.body_GoogleSlides {
  padding: 0px;
  margin: 0px;
  background: black;
}

.blackout-logo img {
  width: 700px;
}

.blackout-mobile-message {
  font-size: 5em;
  margin-top: 35vh;
}

.blackout-mobile-message i {
  text-align: center;
  margin: 0px auto;
}

.blackout-clock .ClockWrapper {
  background: none;
  margin: 0px auto;
}

.blackout-clock .ClockWrapper .Clock {
  color: white;
  font-size: 10em;
}

.AdminWrapper .AdminContent {
  display: block;
  background-color: #fff;
  color: #000;
  padding: 50px;
}

